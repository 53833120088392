@import url('https://fonts.googleapis.com/css?family=Montserrat:700');

.hero {
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin-top: 5rem;
  height: calc(100vh - 5rem);
}

.hero .carousel {
  width: 100%;
  height: 100%;
}
.hero .carousel .carousel-inner {
  height: 100%;
  width: 100%;
}
.hero .carousel .item1 {
  width: 100%;
  background-image: url(../../images/hero/civil.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}
.hero .carousel .item2 {
  width: 100%;
  background-image: url(../../images/hero/plan.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  height: 100%;
}
.hero .carousel .item3 {
  width: 100%;
  background-image: url(../../images/hero/architecture.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}
.hero .carousel .lamar-container {
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  position: relative;
}
.hero .carousel .lamar-container .text .h1 {
  position: absolute;
  top: 50%;
  left: 5%;
  font-size: 75px;
  transform: translateY(-100px);
  font-weight: 450;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}
.hero .carousel .lamar-container .text .h2 {
  position: absolute;
  top: 50%;
  left: 10%;
  font-size: 40px;
  transform: translateY(-10px);
  font-weight: lighter;
}

.hero .carousel .lamar-container .image {
  width: 35%;
  height: 96%;
  transform: translateY(10%);
}
.hero .carousel .lamar-container .image img {
  width: 100%;
  height: 100%;
}
.hero .carousel .lamar-container .text {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
@media (min-width: 600) and (max-width: 767px) {
  .hero .carousel .lamar-container .image {
    width: 50%;
    height: 50%;
    transform: translateY(0%);
  }
  .hero .carousel .lamar-container .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 355px) {
  .hero .carousel .lamar-container .text {
    position: relative;
    right: 20% !important;
  }
}
@media (max-width: 599px) {
  .hero .carousel .lamar-container .text {
    position: relative;
    right: 17%;
  }
  .hero .carousel .lamar-container .text .h1 {
    position: absolute;
    top: 22%;
    left: 5%;
    font-size: 34px;
    transform: translateY(-49px);
    font-weight: 700;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }
  .hero .carousel .lamar-container .text .h2 {
    position: absolute;
    left: 5%;
    font-size: 20px;
    transform: translateY(0px);
    font-weight: 500;
  }
  .hero .carousel .lamar-container .image {
    width: 70%;
    height: 450px;
    transform: translateY(0%);
    position: relative;
    top: 50px;
    right: 12%;
  }

  .hero .carousel .lamar-container .image img {
    height: 600px;
  }
}

/* Small */
@media (max-width: 767px) {
  .hero .carousel .lamar-container .text .h1 {
    position: absolute;
    top: 22%;
    left: 5%;
    font-size: 32px;
    transform: translateY(-49px);
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }
  .hero .carousel .lamar-container .text .h2 {
    position: absolute;
    left: 5%;
    font-size: 20px;
    transform: translateY(20px);
    font-weight: 500;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero .carousel .lamar-container {
    width: 750px;
  }
  .hero .carousel .lamar-container .text .h1 {
    position: absolute;
    top: 50%;
    left: 5%;
    font-size: 50px;
    transform: translateY(-45px);
    font-weight: 700;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }
  .hero .carousel .lamar-container .text .h2 {
    position: absolute;
    top: 50%;
    left: 10%;
    font-size: 30px;
    transform: translateY(10px);
    font-weight: lighter;
  }
}
/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
  .hero .carousel .lamar-container {
    width: 970px;
  }
  .hero .carousel .lamar-container .text {
    left: 5%;
    top: 20%;
  }
  .hero .carousel .lamar-container .text .h1 {
    font-size: 65px;
    font-weight: 700;
  }
  .hero .carousel .lamar-container .text .h2 {
    position: absolute;
    left: 5%;
    font-size: 30px;
    transform: translateY(20px);
    font-weight: lighter;
  }
}
/* Large */
@media (min-width: 1200px) {
  .hero .carousel .lamar-container {
    width: 1170px;
  }
  .hero .carousel .lamar-container .text {
    left: 5%;
    top: 20%;
  }
}

.hero .first-text {
  color: whitesmoke;
  text-shadow: 2px 5px 20px black;
  /* text-shadow: 2px 5px 50px black; */
}
.hero .second-text {
  text-shadow: 2px 5px 15px black;
  /* font-weight: lighter; */
}
.hero .third-text {
  text-shadow: 2px 5px 15px black;
  /* font-weight: lighter !important; */
}

/* lamar logo */
.hero .lamar-logo {
  position: absolute;
  bottom: 1px;
  right: 5px;
  z-index: 5;
}

/* Large */
@media (max-width: 339px) {
  .hero .lamar-logo {
    font-size: 14px;
  }
}
