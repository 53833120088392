@import url(https://fonts.googleapis.com/css?family=Montserrat:700);
.header {
  background-color: transparent;
  position: fixed;
  top: 0;
  width: 100%;
  height: 5rem;
  z-index: 3;
  transition: 1s linear;
}

/*  phones */
@media (min-width: 531px) and (max-width: 652px) {
  .header {
    top: 3rem;
  }
}
/* @media (min-width: 769px) { */
.header.header-scroll {
  background-color: #fff;
  box-shadow: 0px 2px 2px var(--background-color);
  height: 5rem;
  border-bottom: var(--background-color) 2px solid;
  /* top: 2rem; */
}

.header .lamar-container.lamar-container-scroll {
  display: grid;
  height: 5rem;
  grid-template-columns: 25% 55% 20%;
  padding: 0;
}

@media (max-width: 768px) {
  .header .lamar-container.lamar-container-scroll {
    display: grid;
    grid-template-columns: 47% 35% 20%;
  }
}
@media (max-width: 324px) {
  .header .lamar-container.lamar-container-scroll {
    display: grid;
    grid-template-columns: 51% 32% 20%;
  }
}
.header .lamar-container .image.image-scroll {
  grid-area: 1 / 1;
  height: 5rem;
  width: 90px;
  margin: auto;
  display: flex;
}
.header .lamar-container .image.image-scroll a {
  display: flex;
}
.header .lamar-container .image.image-scroll a div {
  width: 5rem;
  align-items: center;
  position: relative;
  top: 4px;
}
@media (max-width: 450px) {
  .header .lamar-container .image.image-scroll a div {
    width: 5rem;
    position: relative;
    top: 8px;
  }
}
.header .lamar-container .image.image-scroll a div h4 {
  margin: auto;
  color: var(--background-color);
  line-height: 0.9;
}

@media (max-width: 450px) {
  .header .lamar-container .image.image-scroll a div h4 {
    font-size: 18px;
  }
}
.header .lamar-container .image.image-scroll a div h4:nth-child(2) {
  letter-spacing: 3px;
}
.header .lamar-container .main-nav.main-nav-scroll {
  height: 5rem;
}

.header .lamar-container .rightContainer.rightContainer-scroll {
  height: 5rem;
}

.header .lamar-container .main-nav.main-nav-scroll > li > a > a {
  height: 5rem;
}
.header .lamar-container .main-nav.main-nav-scroll > li > a > a:hover {
  background-color: var(--background-color);
  color: #fff;
}
/* } */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .main-nav.main-nav-scroll > li > a > a {
    height: 5rem;
    padding-right: 5px;
  }
}
@media (min-width: 531px) and (max-width: 768px) {
  .header.header-scroll {
    background-color: #fff;
    box-shadow: 0px 2px 2px var(--background-color);
    border-bottom: var(--background-color) 2px solid;
    top: 0;
    height: 5rem;
  }
}

/* very small */
@media (max-width: 399px) {
  .header {
    top: 2rem;
  }
}

/* small phones */
@media (max-width: 530px) {
  .header {
    height: 5rem;
  }

  .header.header-scroll {
    background-color: #fff;
    box-shadow: 0px 2px 2px var(--background-color);
    border-bottom: var(--background-color) 2px solid;
    top: 0;
    height: 5rem;
  }
}

/*  phones */
@media (min-width: 531px) and (max-width: 652px) {
  .header {
    top: 3rem;
  }
}

.header .vertical-nav-container {
  position: absolute;
  background-color: rgba(245, 245, 245, 0.86);
  height: 100vh;
  width: 50%;
  right: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-animation: width 1s linear;
          animation: width 1s linear;
  padding-top: 75px;
}
@media (min-width: 769px) {
  .header .vertical-nav-container {
    display: none;
  }
}
@-webkit-keyframes width {
  0% {
    width: 0;
  }
  50% {
    width: 25%;
  }
  100% {
    width: 50%;
  }
}
@keyframes width {
  0% {
    width: 0;
  }
  50% {
    width: 25%;
  }
  100% {
    width: 50%;
  }
}
.header .vertical-nav-container span {
  font-size: 40px;
}
.header .vertical-nav-container .main-nav-phone {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  transition: 0.5s;
}
.header .vertical-nav-container .main-nav-phone > li {
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.2);
  width: 100%;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  position: relative;
  transition: 0.5s;
}
.header .vertical-nav-container .main-nav-phone .fa-angle-down {
  float: right;
  cursor: pointer;
  transition: 0.5s;
  z-index: 4;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  margin-right: 15px;
  color: var(--background-color);
}
.header .vertical-nav-container .main-nav-phone .fa-angle-up {
  float: right;
  cursor: pointer;
  transition: 0.5s;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  z-index: 4;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  color: var(--background-color);
}
.header .vertical-nav-container .main-nav-phone > li > a {
  padding: 10px;
  width: 100%;
  height: 30px;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  color: var(--background-color);
}
.header .vertical-nav-container .main-nav-phone > li > a > a {
  color: var(--background-color);
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone {
  display: none;
  transition: 1s;
  width: 100%;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone.drop-ul-phone-scroll {
  display: block;
  transition: 0.4s;
  width: 100%;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li {
  width: 100%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li::before {
  content: '';
  position: absolute;
  background-color: black;
  width: 0%;
  height: 2px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: 0.5s;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li:hover::before {
  width: 18%;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li a {
  color: var(--background-color);
  font-size: 17px;
  transition: 0.3s;
  font-weight: 500;
}
.header .vertical-nav-container .main-nav-phone .drop-ul-phone li:hover a {
  font-weight: bold;
}
.header .lamar-container {
  display: grid;
  height: 5rem;
  grid-template-columns: 40% 20% 40%;

  position: relative;
  transition: 1s linear;
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container {
    width: 95%;
    display: grid;
    /* height: 5rem; */
    grid-template-columns: 20% 67% 10%;
    grid-gap: 5px;
    gap: 5px;
    height: 5rem;
    padding: 0;
    margin: 0 auto;
  }
}

/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container {
    display: grid;
    grid-template-columns: 33% 35% 30%;
    grid-gap: 0;
    gap: 0;
    position: relative;
  }
}
/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
  .header .lamar-container {
    display: grid;
    grid-template-columns: 32% 30% 38%;
    grid-gap: 5px;
    gap: 5px;
    position: relative;
  }
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////// */
.header .lamar-container .three-dashs {
  display: none;
  width: 30px;
  position: relative;
  cursor: pointer;
}

.header .lamar-container .three-dashs span {
  position: absolute;
  height: 4px;
  width: 25px;
  background-color: var(--background-color);
  border-radius: 10px;
  transition: 0.5s;
}
.header .lamar-container .three-dashs span:nth-child(1) {
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
  width: 20px;
  left: 3px;
}
.header .lamar-container .three-dashs span:nth-child(2) {
  -webkit-transform: translateY(8px);
          transform: translateY(8px);
  width: 15px;
  left: 3px;
}
.header .lamar-container .three-dashs.active {
  position: relative;
  z-index: 5;
  cursor: pointer;
}
.header .lamar-container .three-dashs.active span:nth-child(3) {
  -webkit-transform: translateX(60px);
          transform: translateX(60px);
}

.header .lamar-container .three-dashs.active span:nth-child(1) {
  -webkit-transform: translateY(0px) rotate(45deg);
          transform: translateY(0px) rotate(45deg);
  width: 25px;
  left: 3px;
  transition-delay: 0.125s;
}
.header .lamar-container .three-dashs.active span:nth-child(2) {
  -webkit-transform: translateY(0px) rotate(315deg);
          transform: translateY(0px) rotate(315deg);
  width: 25px;
  left: 3px;
  transition-delay: 0.25s;
}
/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .three-dashs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    height: 30px;
    box-shadow: 0 10px 20px rgb(0, 0, 0, 0.2);
    border-radius: 4px;
    margin: auto;
    overflow: hidden;
  }
}

/* phones */
@media (min-width: 531px) and (max-width: 768px) {
  .header .lamar-container .three-dashs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    height: 30px;
    box-shadow: 0 10px 20px rgb(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: auto;
    overflow: hidden;
  }
}
/* Small */
@media (min-width: 768px) and (max-width: 991px) {
}
/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
}
/* Large */
@media (min-width: 1200px) {
}

/*center side*/
.header .lamar-container .image {
  width: 160px;
  position: relative;
  transition: 1s linear;
  height: 140px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .image {
    width: 5rem;
    height: 60px;
  }
}

/* phones */
@media (min-width: 653px) and (max-width: 768px) {
  .header .lamar-container .image {
    width: 110px;
    height: 90px;
  }
}

/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .image {
    width: 130px;
    height: 110px;
  }
}

.header .lamar-container .image .logo {
  width: 100px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: -55px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
  transition: 1s linear;
}
.header .lamar-container .image .small-logo {
  width: 55px;
  height: 50px;
  transition: 1s linear;
}
@media (max-width: 450px) {
  .header .lamar-container .image .small-logo {
    width: 45px;
    height: 45px;
  }
}

/* left side*/
.header .lamar-container .main-nav {
  display: flex;
  transition: 1s linear;
  position: relative;
}

.header .lamar-container .main-nav li {
  position: relative;
  height: 5rem;
  margin: auto 0;
  display: flex;
  justify-content: center;
  text-align: center;
  transition: 0.5s;
  width: 100%;
  cursor: pointer;
}

.header .lamar-container .main-nav li:hover ul {
  display: block;
}
.header .lamar-container .main-nav > li > a {
  width: 100%;
}
.header .lamar-container .main-nav > li > a > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  color: var(--background-color);
  width: 100%;
  overflow: hidden;
  font-size: 18px;
  transition: var(--main-transition);
  cursor: pointer;
  text-transform: uppercase;
}
.header .lamar-container .main-nav > li > a > a:hover {
  padding-top: 5px;
  /* text-shadow: black; */
  color: #fff;
  /* background-color: var(--background-color); */
}

.header .lamar-container .main-nav > li > a > a > i {
  font-size: 15px;
  margin-left: 15px;
}
/* for drop down  */
.header .lamar-container .main-nav .drop-ul-home {
  display: none;
  position: absolute;
  top: 100%;
  left: 0px;
  background: #ececec;
  height: auto;
  width: 100%;
}

.header .lamar-container .main-nav .drop-ul-home li {
  width: 100%;
  height: 30px;
  background-color: #fafafa;
  box-shadow: 0 2px 12px rgb(0, 0, 0, 0.2);
  transition: 0.5s;
  cursor: pointer;
}
.header .lamar-container .main-nav .drop-ul-home a {
  display: flex;
  align-items: center;
  color: black;
  font-size: 15px;
  height: 30px;
  transition: 0.3s;
  width: 100%;
  padding-left: 5px;
  cursor: pointer;
  text-transform: capitalize;
}
/* end drop down  */
/* start  drop down on scroll */
.header .lamar-container .main-nav .drop-ul-home-scroll {
  display: none;
  position: absolute;
  top: 100%;
  left: 0px;
  background: #fafafa;
  height: auto;
  width: 100%;
}
.header .lamar-container .main-nav .drop-ul-home-scroll li {
  width: 100%;
  height: 30px;
  background-color: #fafafa;
  box-shadow: 0 2px 5px var(--background-color);
  color: var(--background-color);
  transition: 0.5s;
  cursor: pointer;
}
.header .lamar-container .main-nav .drop-ul-home-scroll a {
  display: flex;
  align-items: center;
  color: var(--background-color);
  font-size: 15px;
  height: 30px;
  transition: 0.3s;
  width: 100%;
  padding-left: 5px;
  cursor: pointer;
  text-transform: capitalize;
}

.header .lamar-container .main-nav li > ul > li:hover {
  padding-left: 10px;
  /* background-color: #121314; */
  background-color: var(--background-color);
  color: #fff;
}
.header .lamar-container .main-nav li > ul > li:hover a {
  color: whitesmoke;
}
/* end drop down  */

/* phones */
@media (max-width: 768px) {
  .header .lamar-container .main-nav {
    display: none;
  }
}

/* right container >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
.header .lamar-container .rightContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  transition: 1s linear;
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .rightContainer {
    height: 5rem;
  }
}
.header .lamar-container .rightContainer .right-nav {
  display: flex;
  grid-gap: 3px;
  gap: 3px;
}
@media (min-width: 350px) {
  .header .lamar-container .rightContainer .right-nav {
    grid-gap: 7px;
    gap: 7px;
  }
}
.header .lamar-container .rightContainer .right-nav > li > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  color: black;
  padding: 0 5px;
  cursor: pointer;
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .rightContainer .right-nav > li > a {
    padding: 0;
  }
}
/* phones */
@media (min-width: 531px) and (max-width: 768px) {
  .header .lamar-container .rightContainer .right-nav > li > a {
    padding: 0;
  }
}
/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .rightContainer .right-nav > li > a {
    padding: 0;
  }
}

/* search box styling >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

.header .lamar-container .rightContainer .searchContainer {
  background: transparent;
  height: 30px;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.8s;
}

.header .lamar-container .rightContainer .searchContainer .input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 0px;
  font-weight: 500;
  font-size: 16px;
  transition: 0.8s;
  width: 180px;
  border-bottom: 1px solid;
  cursor: auto;
}
.header .lamar-container .rightContainer .searchContainer .input::-webkit-input-placeholder {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.header .lamar-container .rightContainer .searchContainer .input:-ms-input-placeholder {
  -ms-transition: 0.3s;
  transition: 0.3s;
}
.header .lamar-container .rightContainer .searchContainer .input::placeholder {
  transition: 0.3s;
}

.header .lamar-container .rightContainer .searchContainer .input:focus::-webkit-input-placeholder {
  opacity: 0;
}

.header .lamar-container .rightContainer .searchContainer .input:focus:-ms-input-placeholder {
  opacity: 0;
}

.header .lamar-container .rightContainer .searchContainer .input:focus::placeholder {
  opacity: 0;
}
/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .rightContainer .searchContainer .input {
    width: 100%;
  }
}
/* phones */
@media (min-width: 531px) and (max-width: 768px) {
  .header .lamar-container .rightContainer .searchContainer .input {
    width: 100%;
  }
}

/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .rightContainer .searchContainer .input {
    width: 100px;
  }
}

.header .lamar-container .rightContainer .searchContainer .btn .fas {
  color: black;
}

.header .lamar-container .rightContainer .right-nav .header-icons {
  font-size: 35px;
  padding: 0 6px;
}
.header .lamar-container .rightContainer .right-nav li {
  position: relative;
}

.header .lamar-container .rightContainer .right-nav a .number {
  color: var(--main-transition);
  position: absolute;
  right: 0px;
  top: 26px;
  font-size: 14px;
  margin: 0;
  font-weight: bold;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color-alt);
  text-align: center;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.582);
}

.header .lamar-container .rightContainer .right-nav .cart {
  border: black 1px solid;
  border-radius: 5px;
  font-size: 30px;
  transition: 0.3s;
  cursor: pointer;
}
.header .lamar-container .rightContainer .right-nav .cart:hover {
  color: whitesmoke;
  background-color: black;
  box-shadow: 0 2px 12px rgb(0, 0, 0, 0.5);
}

/* small phones */
@media (max-width: 530px) {
  .header .lamar-container .rightContainer .right-nav .cart {
    margin-left: 4px;
  }
}
/* phones */
@media (min-width: 531px) and (max-width: 768px) {
  .header .lamar-container .rightContainer .right-nav .cart {
    margin-left: 4px;
  }
}

/* Small */
@media (min-width: 769px) and (max-width: 991px) {
  .header .lamar-container .rightContainer .right-nav .cart {
    margin-left: 4px;
  }
}

.header .lamar-container .rightContainer .right-nav li {
  cursor: pointer;
  transition: 0.3s;
}
.header .lamar-container .rightContainer .right-nav .profile {
  cursor: pointer;
  transition: 0.3s;
}
.header .lamar-container .rightContainer .right-nav .profile:hover {
  color: whitesmoke;
  box-shadow: 0 2px 12px rgb(0, 0, 0, 0.5);
  background-color: black;
  border-radius: 25%;
  cursor: pointer;
}
.header .lamar-container .rightContainer .right-nav .fav {
  border: black 1px solid;
  border-radius: 5px;
  font-size: 30px;
  cursor: pointer;
  transition: 0.3s;
}
.header .lamar-container .rightContainer .right-nav .fav:hover {
  color: whitesmoke;
  background-color: black;
  box-shadow: 0 2px 12px rgb(0, 0, 0, 0.5);
}

/* small phones */
@media (max-width: 530px) {
}

/* phones */
@media (min-width: 531px) and (max-width: 768px) {
}
/* Small */
@media (min-width: 769px) and (max-width: 991px) {
}
/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
}
/* Large */
@media (min-width: 1200px) {
}

.right-nav .fab {
  font-size: 24px;
  cursor: pointer;
  color: var(--background-color);
}
@media (max-width: 324px) {
  .right-nav .fab {
    font-size: 20px;
  }
}
/* language slect styles */
.header .selectpicker {
  width: 45px;
  color: var(--background-color);
}
@media (max-width: 324px) {
  .header .selectpicker {
    width: 41px;
    font-size: 14px;
  }
}

.brand {
  background-color: var(--background-color);
  padding-bottom: var(--main-padding-bottom);
  padding-top: var(--main-padding-top);
  position: relative;
}
@media (max-width: 530px) {
  .brand {
    padding-top: 40px;
  }
}
.brand .title-section {
  margin: 0 auto 0;
}

.brand .mainTitle {
  color: var(--text-color);
}

.clinic {
  display: flex;
  text-align: center;
  justify-content: space-around;
  padding: 20px;
}

.clinic .part {
  margin: 10px;
  width: 25%;
}
.clinic .part h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-color);
}
.clinic .part p {
  margin-top: 20px;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: var(--text-color);
  line-height: 1.7;
  font-size: 16px;
}
.clinic i {
  font-size: 50px;
  color: var(--text-color);
  margin-bottom: 20px;
}

.brand .custom-shape-divider-top-1638712956 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.brand .custom-shape-divider-top-1638712956 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 90px;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.brand .custom-shape-divider-top-1638712956 .shape-fill {
  fill: rgba(0, 0, 0, 0.89);
}

.brand .lamar-container {
  padding: 30px;
  display: grid;
  grid-template-areas:
    'lamar lamar montaha montaha nero nero'
    'lamar lamar shera shera shera shera';
  /* grid-gap: 10px; */
  /* height: 500px; */
}
/* @media (max-width: 767px) {
	.brand .lamar-container {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		gap: 30px;
		width: 100%;
		height: 600px;
	}
} */

.item1 {
  grid-area: lamar;
}
.item2 {
  grid-area: montaha;
}
.item3 {
  grid-area: nero;
}
.item4 {
  grid-area: shera;
}
.brand .lamar-container > div {
  padding: 30px;
  position: relative;
  box-shadow: 0 2px 12px var(--text-color);
  height: 100%;
  transition: box-shadow 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, box-shadow 0.5s;
  transition: transform 0.5s, box-shadow 0.5s, -webkit-transform 0.5s;
  cursor: pointer;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .brand .lamar-container > div {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
  }
  .clinic .part {
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .brand .lamar-container > div {
    display: flex;
    flex-direction: column;
  }
  .clinic .part {
    width: 100%;
    margin: 0;
  }
}
/* @media (max-width: 767px) {
	.brand .lamar-container > div {
		padding: 5px;
		position: relative;
		box-shadow: 0 2px 12px rgba(8, 7, 7, 0.2);
		transition: transform 0.5s, box-shadow 0.5s;
		height: 700px;
		width: 80%;
		margin: 0 auto;
	}

	.clinic .part {
		width: 100%;
	}
} */

/* start item one ----------- */
.brand .item1 {
  background-color: var(--main-color-alt2);
}
.brand .item1:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
}
.brand .item1 .image {
  position: absolute;
  left: -60px;
  bottom: 0;
  height: 110%;
  width: 100%;
}
.brand .item1 .image img {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.brand .item1 h3 {
  position: absolute;
  top: 10px;
  right: 30px;
  font-size: 40px;
  text-transform: capitalize;
  font-weight: 700;
}

@media (max-width: 767px) {
  .brand .lamar-container .item1 .image {
    position: absolute;
    left: -10px;
    bottom: 0;
    height: 120%;
    width: 40%;
  }
  .brand .lamar-container .item1 h3 {
    position: absolute;
    top: 50%;
    right: 30%;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    font-size: 35px;
    text-transform: capitalize;
    font-weight: 700;
  }
}
/* end item one  */

/* start item tow -------- */
.brand .item2 {
  background-color: #ffd369;
}
.brand .item2:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
}
.brand .item2 .image {
  position: absolute;
  right: -35px;
  bottom: 0;
  height: 128%;
  width: 65%;
}
.brand .item2 .image img {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.brand .item2 h3 {
  position: absolute;
  bottom: 35px;
  left: 20px;
  color: var(--main-color-alt2);
  font-size: 40px;
  text-transform: capitalize;
  font-weight: 700;
}

@media (max-width: 767px) {
  .brand .lamar-container .item2 .image {
    position: absolute;
    right: 10px;
    bottom: 0;
    height: 120%;
    width: 40%;
  }
  .brand .lamar-container .item2 h3 {
    position: absolute;
    bottom: 14px;
    left: 22px;
    color: var(--main-color-alt2);
    font-size: 35px;
    text-transform: capitalize;
    font-weight: 700;
  }
}
/* end item tow  */
/* start item three------------  */
.brand .item3 {
  background-color: #d7385e;
}
.brand .item3:hover {
  -webkit-transform: translatex(10px);
          transform: translatex(10px);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
}
.brand .item3 .image {
  position: absolute;
  left: -35px;
  bottom: 0;
  height: 113%;
  width: 65%;
}
.brand .item3 .image img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.brand .item3 h3 {
  position: absolute;
  bottom: 35px;
  right: 20px;
  color: var(--main-color-alt2);
  font-size: 40px;
  text-transform: capitalize;
  font-weight: 700;
}
@media (max-width: 767px) {
  .brand .lamar-container .item3 .image {
    position: absolute;
    left: -10px;
    bottom: 0;
    height: 120%;
    width: 54%;
  }
  .brand .lamar-container .item3 h3 {
    position: absolute;
    bottom: 16px;
    right: 43px;
    /* transform: translate(50%,-50%); */
    font-size: 35px;
    text-transform: capitalize;
    font-weight: 700;
  }
}
/* end item three------------  */
/* start item four------------  */
.brand .item4 {
  background-color: #41584b;
}
.brand .item4:hover {
  -webkit-transform: translatex(10px);
          transform: translatex(10px);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
}

.brand .item4 .image {
  position: absolute;
  right: 90px;
  bottom: 0;
  height: 106%;
  width: 30%;
}
.brand .item4 .image img {
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.brand .item4 h3 {
  position: absolute;
  font-size: 40px;
  font-weight: 600;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 100px;
  color: var(--main-color-alt2);
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .brand .lamar-container .item4 .image {
    position: absolute;
    right: 20px;
    bottom: 0;
    height: 100%;
    width: 30%;
  }
  .brand .lamar-container .item4 h3 {
    position: absolute;
    bottom: 5px;
    left: 20px;
    color: var(--main-color-alt2);
    font-size: 30px;
    text-transform: capitalize;
    font-weight: 700;
  }
}
/* end item four------------  */

.hero {
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin-top: 5rem;
  height: calc(100vh - 5rem);
}

.hero .carousel {
  width: 100%;
  height: 100%;
}
.hero .carousel .carousel-inner {
  height: 100%;
  width: 100%;
}
.hero .carousel .item1 {
  width: 100%;
  background-image: url(/static/media/civil.ffa032f4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}
.hero .carousel .item2 {
  width: 100%;
  background-image: url(/static/media/plan.8bf4fc02.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  height: 100%;
}
.hero .carousel .item3 {
  width: 100%;
  background-image: url(/static/media/architecture.2954e87b.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}
.hero .carousel .lamar-container {
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  position: relative;
}
.hero .carousel .lamar-container .text .h1 {
  position: absolute;
  top: 50%;
  left: 5%;
  font-size: 75px;
  -webkit-transform: translateY(-100px);
          transform: translateY(-100px);
  font-weight: 450;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}
.hero .carousel .lamar-container .text .h2 {
  position: absolute;
  top: 50%;
  left: 10%;
  font-size: 40px;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  font-weight: lighter;
}

.hero .carousel .lamar-container .image {
  width: 35%;
  height: 96%;
  -webkit-transform: translateY(10%);
          transform: translateY(10%);
}
.hero .carousel .lamar-container .image img {
  width: 100%;
  height: 100%;
}
.hero .carousel .lamar-container .text {
  flex: 1 1;
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
@media (min-width: 600) and (max-width: 767px) {
  .hero .carousel .lamar-container .image {
    width: 50%;
    height: 50%;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .hero .carousel .lamar-container .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 355px) {
  .hero .carousel .lamar-container .text {
    position: relative;
    right: 20% !important;
  }
}
@media (max-width: 599px) {
  .hero .carousel .lamar-container .text {
    position: relative;
    right: 17%;
  }
  .hero .carousel .lamar-container .text .h1 {
    position: absolute;
    top: 22%;
    left: 5%;
    font-size: 34px;
    -webkit-transform: translateY(-49px);
            transform: translateY(-49px);
    font-weight: 700;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }
  .hero .carousel .lamar-container .text .h2 {
    position: absolute;
    left: 5%;
    font-size: 20px;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    font-weight: 500;
  }
  .hero .carousel .lamar-container .image {
    width: 70%;
    height: 450px;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    position: relative;
    top: 50px;
    right: 12%;
  }

  .hero .carousel .lamar-container .image img {
    height: 600px;
  }
}

/* Small */
@media (max-width: 767px) {
  .hero .carousel .lamar-container .text .h1 {
    position: absolute;
    top: 22%;
    left: 5%;
    font-size: 32px;
    -webkit-transform: translateY(-49px);
            transform: translateY(-49px);
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }
  .hero .carousel .lamar-container .text .h2 {
    position: absolute;
    left: 5%;
    font-size: 20px;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    font-weight: 500;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero .carousel .lamar-container {
    width: 750px;
  }
  .hero .carousel .lamar-container .text .h1 {
    position: absolute;
    top: 50%;
    left: 5%;
    font-size: 50px;
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    font-weight: 700;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }
  .hero .carousel .lamar-container .text .h2 {
    position: absolute;
    top: 50%;
    left: 10%;
    font-size: 30px;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    font-weight: lighter;
  }
}
/* Medium */
@media (min-width: 992px) and (max-width: 1199px) {
  .hero .carousel .lamar-container {
    width: 970px;
  }
  .hero .carousel .lamar-container .text {
    left: 5%;
    top: 20%;
  }
  .hero .carousel .lamar-container .text .h1 {
    font-size: 65px;
    font-weight: 700;
  }
  .hero .carousel .lamar-container .text .h2 {
    position: absolute;
    left: 5%;
    font-size: 30px;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    font-weight: lighter;
  }
}
/* Large */
@media (min-width: 1200px) {
  .hero .carousel .lamar-container {
    width: 1170px;
  }
  .hero .carousel .lamar-container .text {
    left: 5%;
    top: 20%;
  }
}

.hero .first-text {
  color: whitesmoke;
  text-shadow: 2px 5px 20px black;
  /* text-shadow: 2px 5px 50px black; */
}
.hero .second-text {
  text-shadow: 2px 5px 15px black;
  /* font-weight: lighter; */
}
.hero .third-text {
  text-shadow: 2px 5px 15px black;
  /* font-weight: lighter !important; */
}

/* lamar logo */
.hero .lamar-logo {
  position: absolute;
  bottom: 1px;
  right: 5px;
  z-index: 5;
}

/* Large */
@media (max-width: 339px) {
  .hero .lamar-logo {
    font-size: 14px;
  }
}

.feedback {
  background-color: var(--background-color);
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 290px;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 490px) {
  .feedback {
    height: 430px;
  }
}

@media (max-width: 490px) {
  .feedback .lamar-container {
    padding-left: 0;
    padding-right: 0;
  }
}
.slide-container {
  text-align: center;
  width: 70%;
  margin: auto;
}

.shop {
  /* background-image: url(https://www.almotahajiba.com/pub/media/wysiwyg/smartwave/porto/homepage/15/slider/parallax_img_new.jpg); */
  /* background-image: url(../../images/shop/shop3.jpg); */
  /* background-image: url(https://firebasestorage.googleapis.com/v0/b/lamar-fashion.appspot.com/o/home-shop%2Fshop5.jpeg?alt=media&token=8705dc05-216b-403f-b64b-c80df640cd4e); */
  padding-top: 50px;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: scroll;
  background-size: cover;
  /* height: 80vh; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shop .lamar-container {
  /* height: 55vh; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 20px; */
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 10px;
  box-shadow: 0 2px 12px var(--background-color);
}

.shop .rightSection .image {
  width: 90%;
}

/* @media (max-width: 529px) {
	.shop .lamar-container {
		gap: 10px;
		flex-direction: column;
		width: 90%;
		height: 50vh;
	}
}
@media (min-width: 530px) and (max-width: 768px) {
	.shop .lamar-container {
		gap: 10px;
		flex-direction: column;
		width: 95%;
	}
} */

@media (max-width: 991px) {
  .shop .lamar-container {
    height: 100%;
    display: flex;

    flex-direction: column;
    width: 90%;
  }
  .shop .lamar-container .leftSection {
    width: 100%;
    position: relative;
    padding: 0 30px;
    text-align: center;
    margin: 30px;
  }
  .shop .lamar-container .rightSection {
    position: relative;
    flex: 1 1;
    height: 100%;
    text-align: center;
    width: 70%;
  }
}

/* @media (min-width: 992px) and (max-width: 1199px) {
	.shop .lamar-container {
		gap: 20px;
	}
} */

.shop .leftSection {
  width: 50%;
  position: relative;
  padding-left: 30px;
  text-align: justify;
}
@media (max-width: 529px) {
  .shop .lamar-container .leftSection {
    width: 100%;
  }
}

/* @media (min-width: 530px) and (max-width: 768px) {
	.shop .lamar-container .leftSection {
		width: 100%;
		position: relative;
	}
}

/* @media (min-width: 530px) and (max-width: 768px) {
	.shop .lamar-container .leftSection {
		width: 100%;
		position: relative;
	}
}
@media (min-width: 769px) and (max-width: 991px) {
	.shop .lamar-container .leftSection {
		width: 40%;
		position: relative;
	}
} */
/* @media (min-width: 992px) and (max-width: 1199px) {
	.shop .lamar-container .leftSection {
		width: 42%;
	}
} */
.shop .leftSection .shop-title {
  color: var(--text-color);
}
.shop .leftSection .text {
  font-size: 21px;
  /* font-weight: 550; */
  color: var(--text-color);
}
/* @media (max-width: 529px) {
	.shop .lamar-container .leftSection .shop-title {
		font-size: 15px;
		font-weight: bold;
		color: var(--main-color-alt);
	}
	.shop .lamar-container .leftSection .text {
		font-size: 19px;
		font-weight: 500;
		color: var(--text-color);
	}
} */

/* @media (min-width: 530px) and (max-width: 768px) {
	.shop .lamar-container .leftSection .shop-title {
		
		font-size: 17px;
		font-weight: bold;
		color: var(--main-color-alt);
	}
	.shop .lamar-container .leftSection .text {
		font-size: 20px;
		font-weight: 500;
		color: var(--text-color);
	}
} */
/* @media (min-width: 769px) and (max-width: 991px) {
	.shop .lamar-container .leftSection .shop-title {
		font-size: 20px;
		font-weight: bold;
		color: var(--main-color-alt);
	}
	.shop .lamar-container .leftSection .text {
		font-size: 22px;
		font-weight: 500;
		color: var(--text-color);
	}
} */
.shop .rightSection {
  position: relative;
  flex: 1 1;
}

.shop .shopImg {
  position: absolute;
  padding: 4px;
  background-color: var(--main-color-alt);
}

.shop .rightSection .image1 {
  height: 220px;
  width: 180px;
  top: 45px;
  left: 15px;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
}
.shop .rightSection .image1 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 529px) {
  .shop .lamar-container .rightSection .image1 {
    height: 150px;
    width: 120px;
    top: 0px;
    left: -150px;
    -webkit-transform: rotate(-6deg);
            transform: rotate(-6deg);
  }
}
@media (min-width: 530px) and (max-width: 768px) {
  .shop .lamar-container .rightSection .image1 {
    height: 140px;
    width: 130px;
    top: -45px;
    left: -70px;
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}

@media (max-width: 991px) {
  .shop .lamar-container .rightSection {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .shop .rightSection .image {
    width: 100%;
    height: 87%;
  }
}

@media (max-width: 991px) {
  .shop .rightSection .image {
    width: 175%;
    height: 230px;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .shop .lamar-container .rightSection .image1 {
    height: 200px;
    width: 160px;
    top: 35px;
    left: 15px;
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}

.shop .rightSection .image2 {
  height: 230px;
  width: 190px;
  top: 60px;
  left: 160px;
  -webkit-transform: rotate(1.5deg);
          transform: rotate(1.5deg);
}
.shop .rightSection .image2 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 529px) {
  .shop .lamar-container .rightSection .image2 {
    height: 160px;
    width: 130px;
    top: 15px;
    left: -54px;
    -webkit-transform: rotate(1.5deg);
            transform: rotate(1.5deg);
  }
}
@media (min-width: 530px) and (max-width: 768px) {
  .shop .lamar-container .rightSection .image2 {
    height: 160px;
    width: 130px;
    top: -55px;
    left: 25px;
    -webkit-transform: rotate(1.5deg);
            transform: rotate(1.5deg);
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .shop .lamar-container .rightSection .image2 {
    height: 210px;
    width: 170px;
    top: 45px;
    left: 135px;
    -webkit-transform: rotate(1.5deg);
            transform: rotate(1.5deg);
  }
}

.shop .rightSection .image3 {
  height: 210px;
  width: 160px;
  top: 45px;
  right: 60px;
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
}
.shop .rightSection .image3 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 529px) {
  .shop .lamar-container .rightSection .image3 {
    height: 150px;
    width: 120px;
    top: -2px;
    left: 39px;
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}
@media (min-width: 530px) and (max-width: 768px) {
  .shop .lamar-container .rightSection .image3 {
    height: 150px;
    width: 120px;
    top: -55px;
    left: 122px;
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .shop .lamar-container .rightSection .image3 {
    height: 190px;
    width: 150px;
    top: 35px;
    right: 10px;
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}

/* .shop  .rightSection .image4 {
  height: 300px;
  position: absolute;
  bottom: -42px;
  right: 65px;
}
.shop  .rightSection .image4 img{
  height: 100%;
  width: 100%;
  object-fit: cover;
} */

.shop .text {
  margin-bottom: 1rem;
  font-weight: lighter;
}

.footer {
  background-color: #fff;
  padding: 70px 0 0;
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}

.footer .go-up {
}
.footer .lamar-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 40px;
  gap: 40px;
}
@media (min-width: 1200px) {
  .footer .lamar-container {
    width: 1170px !important;
  }
}
@media (max-width: 375px) {
  .footer .lamar-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
    gap: 20px;
  }
}
.footer .box h3 {
  color: var(--background-color);
  font-size: 50px;
  margin: 0;
}
.footer .box .social {
  display: flex;
}
@media (max-width: 767px) {
  .footer .box .social {
    justify-content: center;
  }
}
.footer .box .social li {
  margin-right: 10px;
  cursor: pointer;
}
.footer .box .social li a {
  background-color: #ccc;
  color: var(--background-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  transition: 0.5s;
  cursor: pointer;
}
.footer .box .social li a i {
  cursor: pointer;
}
.footer .box .social .facebook:hover {
  background-color: #1877f2;
}
.footer .box .social .twitter:hover {
  background-color: #1da1f2;
}
.footer .box .social .instagram:hover {
  background: linear-gradient(15deg, #ffb13d, #dd277b, #4d5ed4);
}
.footer .box .text {
  line-height: 2;
  color: var(--background-color);
}
.footer .box .links li {
  padding: 15px 0;
  transition: var(--main-transition);
  cursor: pointer;
}
.footer .box .links li:not(:last-child) {
  border-bottom: 1px solid var(--background-color);
}
.footer .box .links li:hover {
  padding-left: 10px;
}
.footer .box .links li:hover a {
  color: var(--background-color);
}
.footer .box .links li a {
  color: var(--background-color);
  transition: var(--background-color);
  cursor: pointer;
}
.footer .box .links li a i {
  font-size: 14px;
  margin-right: 3px;
  color: var(--background-color);
}
.footer .box .line {
  display: flex;
  align-items: center;
  color: var(--background-color);
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .footer .box .line {
    flex-direction: column;
  }
}
.footer .box .line i {
  font-size: 25px;
  color: var(--background-color);
  margin-right: 10px;
}
@media (max-width: 767px) {
  .footer .box .line i {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.footer .box .line .info {
  line-height: 1.7;
  flex: 1 1;
  color: var(--background-color);
}
.footer .box .line .info span {
  display: block;
}
.footer .box .line .info a {
  color: var(--background-color);
}

.footer .box p {
  color: var(--background-color);
  margin-bottom: 5px;
}
.footer .footer-gallery img {
  width: 78px;
  border: 3px solid var(--background-color);
  margin: 2px;
}

.footer ul {
  padding-left: 0;
}
.footer .footer-pay .lamar-container {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 10px;
  gap: 10px;
  border-top: 1px solid var(--background-color);
  margin-top: 10px;
}
@media (max-width: 530px) {
  .footer .footer-pay .lamar-container {
    display: grid;
    grid-template-columns: 100%;
  }
}

.footer .footer-pay .lamar-container .copyright {
  padding: 25px 0;
  text-align: left;
  color: var(--background-color);
}
@media (max-width: 530px) {
  .footer .footer-pay .lamar-container .copyright {
    padding: 25px 0;
    text-align: center;
    color: var(--background-color);
  }
}
.footer .footer-pay .lamar-container .info-pay {
  display: flex;
  justify-content: end;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
@media (max-width: 530px) {
  .footer .footer-pay .lamar-container .info-pay {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    grid-area: 1 / 1;
  }
}
.footer .info-pay .image {
  width: 60px;
  height: 40px;
  margin: 0 5px;
  cursor: pointer;
}
.footer .info-pay .image.pay-pal {
  width: 70px;
  height: 40px;
  margin: 0 5px;
  cursor: pointer;
}
.footer .info-pay img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.fixed-header {
  background-color: black;
  position: fixed;
  height: 2rem;
  width: 100%;
  color: var(--main-color-alt);
  z-index: 4;
}
/* very small */
@media (max-width: 399px) {
  .fixed-header {
    height: 2rem;
  }
}

/* small phones */
@media (max-width: 530px) {
  .fixed-header {
    /* height: 4rem; */
  }
}

/* phones */
@media (min-width: 531px) and (max-width: 652px) {
  .fixed-header {
    height: 3rem;
  }
}

.fixed-header .lamar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
}

/* very small */
@media (max-width: 399px) {
  .fixed-header .lamar-container {
    height: 2rem;
  }
}

/* small phones */
@media (max-width: 530px) {
  .fixed-header .lamar-container {
    /* height: 4rem; */
  }
}

/* phones */
@media (min-width: 531px) and (max-width: 652px) {
  .fixed-header .lamar-container {
    height: 3rem;
  }
}

.fixed-header .lamar-container .contact-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}

/* small phones */
@media (max-width: 530px) {
  .fixed-header .lamar-container .contact-container {
    grid-gap: 5px;
    gap: 5px;
  }
}

/* phones */
@media (min-width: 531px) and (max-width: 710px) {
  .fixed-header .lamar-container .contact-container {
    grid-gap: 5px;
    gap: 5px;
  }
}

.fixed-header .lamar-container .contact-container .contact-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

/* small phones */
@media (max-width: 530px) {
  .fixed-header .lamar-container .contact-container .contact-info.location {
    display: none;
  }
}
/* small phones */
@media (max-width: 530px) {
  .fixed-header .lamar-container .contact-container .contact-info.email {
    display: none;
  }
}

.fixed-header .lamar-container .contact-container .contact-info .contact-text {
  margin: auto;
  font-size: 14px;
}

.fixed-header .icons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}
.fixed-header .icons-container .icons {
color: var(--main-color-alt);
}
.fixed-header .icons-container .icons i{
  cursor: pointer;
}

.about-us {
	padding: 200px 0 100px;
	width: 100%;
	background-color: var(--main-color-alt2);
	position: relative;
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
	color: var(--background-color);
}
@media (max-width: 530px) {
	.about-us {
		padding: 150px 0 100px;
	}
}
.about-us .lamar-container {
	padding-bottom: 10px;
}

.about-us .lamar-container .experience div {
	margin-top: 40px;
}

.about-us .lamar-container .eductation {
	margin-top: 40px;
}
@media (max-width: 767px) {
	.about-us .lamar-container {
		width: 100%;
	}
}
.about-us .path {
	text-align: left;
}
.about-us .path i {
	font-size: 22px;
	margin-right: 10px;
	color: var(--background-color);
}
.about-us .path span {
	font-size: 20px;
	cursor: auto;
	font-weight: 600;
}
.about-us .lamar-container > ul {
	list-style: disc;
	padding: 10px 20px !important;
	margin: 10px !important;
}
.about-us .lamar-container > ul > li {
	margin: 10px auto;
}
.about-us .lamar-container > ul > li span {
	font-weight: bold;
}

.delivery-p{
    padding: 200px 0 100px;
    width: 100%;
    background-color: var(--main-color-alt2);
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}
@media(max-width:530px){
    .delivery-p{
      padding: 150px 0 100px; 
    }
  }

.delivery-p .lamar-container {
    padding-bottom:10px;
}

@media(max-width:767px){
    .delivery-p .lamar-container {
        width: 100%;
    }
}

.delivery-p  .lamar-container .path{
    text-align: left;
}
.delivery-p .lamar-container .path i {
    font-size: 22px;
    margin-right: 10px;
    color: var(--main-color-alt);
}
.delivery-p  .lamar-container .path span{
    font-size: 20px;
    cursor: auto;
    font-weight: 600;
}
.delivery-p .lamar-container p {
    padding: 0 auto;
}
.delivery-p .lamar-container p span{
    font-weight: bold;
}
.delivery-p .lamar-container ul li {
    font-weight: 700;
    padding: 0 0 0 40px;
    color: var(--text-color);
}
.privacy{
    padding: 200px 0 100px;
    width: 100%;
    background-color: var(--main-color-alt2);
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}
@media(max-width:530px){
    .privacy{
      padding: 150px 0 100px; 
    }
  }

.privacy .lamar-container {
    padding-bottom:10px;
}

@media(max-width:767px){
    .privacy .lamar-container {
        width: 100%;
    }
}

.privacy  .lamar-container .path{
    text-align: left;
}
.privacy .lamar-container .path i {
    font-size: 22px;
    margin-right: 10px;
    color: var(--main-color-alt);
}
.privacy  .lamar-container .path span{
    font-size: 20px;
    cursor: auto;
    font-weight: 600;
}
.privacy  .lamar-container ol > li {
    font-weight: 600;
    padding-left:  5px;
    margin-bottom: 10px;
}

.privacy  .lamar-container ol  ul li {
    list-style: disc;

}

.privacy  .lamar-container p span {
    font-weight: bold;
}
.privacy  .lamar-container p {
    word-spacing: 1.8px;
}
.privacy  .lamar-container:nth-child(2) p{
    margin-top: 10px;
}
.contact-us {
  padding: 220px 0 100px;
  background-color: var(--main-color-alt2);
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--background-color);
}
@media (max-width: 530px) {
  .contact-us {
    padding: 125px 0;
  }
}
@media (min-width: 530px) and (max-width: 767px) {
  .contact-us {
    padding: 145px 0;
  }
}
.contact-us .nav-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
  width: 100%;
}
.contact-us .nav-info i {
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-right: 10px;
}

.contact-us .left-nav .i-home {
  font-size: 18px;
  /* color: black; */
  font-weight: 600;
}
.contact-us .nav-info span {
  /* color: black; */
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.contact-us .lamar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-us .lamar-container h2 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  /* color: black; */
  position: relative;
  text-align: center;
}
.contact-us .lamar-container h2::before {
  content: '';
  position: absolute;
  height: 5px;
  width: 50%;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: var(--background-color);
  border-radius: 20px;
}
@media (max-width: 767px) {
  .contact-us .lamar-container h2 {
    font-size: 20px;
  }
}

.contact-us .lamar-container .email {
  display: flex;
  margin: 35px;
  align-items: center;
}

.contact-us .lamar-container .email h5 {
  margin-right: 10px;
  color: gray;
}

.contact-us .lamar-container .email a {
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
  color: var(--background-color);
}

